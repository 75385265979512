<template>
    <v-container fluid>
        <v-form @submit.prevent="getProducts">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'order.create'}"
                   color="primary" fab fixed top right class="v-btn--add-form-top white--text">
                <v-icon>mdi-truck-delivery-outline</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pb-0">
                        <v-row>
                            <v-col cols="12" sm="9" class="pl-0">
                                <h3 class="headline pb-2 pa-0" color="black">{{ title }}</h3>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly && cache_order.length > 0" cols="12" sm="3" align="right">
                                <v-btn tile :to="{name: 'order.create'}" color="primary" >
                                    {{$t('place_an_order') }}
                                    <v-icon right>
                                        mdi-truck-delivery-outline
                                    </v-icon>

                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </ValidationObserver>
        </v-form>

        <v-card flat light>
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('search_barcode_name')"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-card-text class="px-0">
                <v-data-table :headers="filteredHeaders" :items="products" :options.sync="options"
                              :loading="loading" :locale="lang"
                              :search="search"
                              :loading-text="$t('loading_please_wait')" :no-results-text ="$t('missing_data')"
                              :no-data-text ="$t('missing_data')"
                              class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="openOrder(item)" :title="$t('add_to_shipment')">
                                    mdi-text-box-plus-outline
                                </v-icon>
                            </template>
                            <span>{{$t('add_to_shipment')}}</span>
                        </v-tooltip>

                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-card flat light>
            <v-col v-if="cache_order.length > 0" cols="12" align="right" class="pa-0">
                <v-btn tile :to="{name: 'order.create'}" color="primary"
                :block="$vuetify.breakpoint.xsOnly"
                >
                    {{$t('place_an_order') }}
                    <v-icon right>
                        mdi-truck-delivery-outline
                    </v-icon>

                </v-btn>
            </v-col>
        </v-card>


        <v-dialog
            transition="dialog-bottom-transition"
            max-width="500"
            v-if="dialogOrder" v-model="dialogOrder"
        >
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <template>
                    <v-card>
                        <v-toolbar
                            color="primary"
                            dark
                        >{{ $t('specify_number_pieces_per_shipment')}}</v-toolbar>
                        <v-card-text>
                                <v-col cols="12" class="mt-4">
                                    <ValidationProvider ref="remaining_client" :rules="`required|numeric|min_value:1|max_value:${max_remaining}`"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="remaining_client" type="text"
                                                      :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('remaining_client')" color="primary"
                                                      outlined
                                                      dense
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                    <v-btn
                                        @click="addProduct"
                                        block
                                        color="primary"
                                        :disabled="invalid || loading"
                                        :loading="loading"
                                    >{{ $t('ship') }}</v-btn>
                                </v-col>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn
                                text
                                @click="closeDialogOrder"
                            >{{ $t('close') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </ValidationObserver>
        </v-dialog>

    </v-container>
</template>


<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters, mapActions} from "vuex";
    import cache from "../plugins/localStorage";


    export default {
        name: "Products",
        components: {
            ValidationObserver,
            ValidationProvider
        },
        data() {
            return {
                options: {},
                cache_order : [],
                dialogOrder: false,
                OrderCache: null,
                title: null,
                name: null,
                loading: false,
                remaining_client: null,
                item_client:null,
                max_remaining: 10000,
                products: [],
                loadingSaveAddress:false,
                search: '',
                headers: [
                    {
                        text: this.$t('barcode'),
                        align: "left",
                        sortable: false,
                        value: "barcode"
                    },
                    {
                        text: this.$t('name'),
                        align: "left",
                        sortable: false,
                        value: "name",
                    },

                    {
                        text: this.$t('price'),
                        align: "center",
                        sortable: false,
                        filterable: false,
                        value: "price",
                    },
                    {
                        text: this.$t('remaining'),
                        align: "center",
                        sortable: false,
                        filterable: false,
                        value: "remaining",
                    },

                    {
                        text: this.$t('ship'),
                        align: this.mini ? "right" : "center",
                        value: 'action',
                        filterable: false,
                        sortable: false,
                        width: 150,
                    },
                ],
            };
        },
        computed: {
            ...mapGetters(['lang','orderCache']),
            filteredHeaders() {
                return this.headers.filter(h => !h.hide)
            },
        },
        watch: {
            options: {
                handler() {
                    this.title = this.$route.params.name;
                    this.cache_order = cache.order;
                    this.getProducts()
                },
                deep: false
            },
        },

        methods: {
            ...mapActions(['setOrderCache']),

            openOrder(item) {
                this.max_remaining = item.remaining;
                this.item_client = item;
                this.dialogOrder = true
            },
            closeDialogOrder() {
                this.dialogOrder = false
            },
            addProduct() {
                let search_product = 0;
                this.item_client.remaining = this.item_client.remaining - this.remaining_client;
                let clone = Object.assign({}, this.item_client);
                clone.remaining = this.remaining_client;
                this.cache_order.forEach((res) => {
                    if(res.product_uuid === clone.product_uuid){
                        res.remaining = parseInt(res.remaining) +  parseInt(this.remaining_client)
                        search_product = 1;
                    }
                })
                if(search_product===0){
                    this.cache_order.push( clone );
                }
                cache.order = this.cache_order;
                this.setOrderCache(cache.order)
                this.remaining_client = null
                clone = null
                this.item_client = null
                this.closeDialogOrder()
            },
            async getProducts() {
                this.loading = true;
                var _this = this
                let params = {};

                if (this.lang) {
                    params.language = this.lang
                }
                params.category_uuid = this.$route.params.id
                await this.$http
                    .get("wms/product", {
                        params: params,
                    })
                    .then(res => {
                        this.products = res.body.data
                        if(this.cache_order.length > 0){
                            this.products.forEach((res)  => {
                                this.cache_order.forEach((res2) => {
                                    if(res.product_uuid === res2.product_uuid){
                                        res.remaining = res.remaining - res2.remaining
                                    }
                                })
                            })
                        }

                    })
                    .catch(err => {
                        this.products = []
                        this.$toastr.error(this.$t('failed_to_get_list_products'));
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    });
            },
      }
    };

</script>
