var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.getProducts.apply(null, arguments)}}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xsOnly),expression:"$vuetify.breakpoint.xsOnly"}],staticClass:"v-btn--add-form-top white--text",attrs:{"to":{name: 'order.create'},"color":"primary","fab":"","fixed":"","top":"","right":""}},[_c('v-icon',[_vm._v("mdi-truck-delivery-outline")])],1),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
var validate = ref.validate;
return [_c('v-card',{attrs:{"flat":"","light":""}},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","sm":"9"}},[_c('h3',{staticClass:"headline pb-2 pa-0",attrs:{"color":"black"}},[_vm._v(_vm._s(_vm.title))])]),(!_vm.$vuetify.breakpoint.xsOnly && _vm.cache_order.length > 0)?_c('v-col',{attrs:{"cols":"12","sm":"3","align":"right"}},[_c('v-btn',{attrs:{"tile":"","to":{name: 'order.create'},"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('place_an_order'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-truck-delivery-outline ")])],1)],1):_vm._e()],1)],1)],1)]}}])})],1),_c('v-card',{attrs:{"flat":"","light":""}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search_barcode_name'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',{staticClass:"px-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.filteredHeaders,"items":_vm.products,"options":_vm.options,"loading":_vm.loading,"locale":_vm.lang,"search":_vm.search,"loading-text":_vm.$t('loading_please_wait'),"no-results-text":_vm.$t('missing_data'),"no-data-text":_vm.$t('missing_data'),"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"title":_vm.$t('add_to_shipment')},on:{"click":function($event){return _vm.openOrder(item)}}},on),[_vm._v(" mdi-text-box-plus-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('add_to_shipment')))])])]}}])})],1)],1),_c('v-card',{attrs:{"flat":"","light":""}},[(_vm.cache_order.length > 0)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","align":"right"}},[_c('v-btn',{attrs:{"tile":"","to":{name: 'order.create'},"color":"primary","block":_vm.$vuetify.breakpoint.xsOnly}},[_vm._v(" "+_vm._s(_vm.$t('place_an_order'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-truck-delivery-outline ")])],1)],1):_vm._e()],1),(_vm.dialogOrder)?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"500"},model:{value:(_vm.dialogOrder),callback:function ($$v) {_vm.dialogOrder=$$v},expression:"dialogOrder"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
var validate = ref.validate;
return [[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(_vm.$t('specify_number_pieces_per_shipment')))]),_c('v-card-text',[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"remaining_client",attrs:{"rules":("required|numeric|min_value:1|max_value:" + _vm.max_remaining)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"label":_vm.$t('remaining_client'),"color":"primary","outlined":"","dense":"","clearable":""},model:{value:(_vm.remaining_client),callback:function ($$v) {_vm.remaining_client=$$v},expression:"remaining_client"}})]}}],null,true)}),_c('v-btn',{attrs:{"block":"","color":"primary","disabled":invalid || _vm.loading,"loading":_vm.loading},on:{"click":_vm.addProduct}},[_vm._v(_vm._s(_vm.$t('ship')))])],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDialogOrder}},[_vm._v(_vm._s(_vm.$t('close')))])],1)],1)]]}}],null,false,253963766)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }